/*
 * This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS and SCSS file within this directory, lib/assets/stylesheets, or any plugin's
 * vendor/assets/stylesheets directory can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the bottom of the
 * compiled file so the styles you add here take precedence over styles defined in any other CSS/SCSS
 * files in this directory. Styles in this file should be added after the last require_* statement.
 * It is generally better to create a new file per style scope.
 *
 *= require_self
 */
//= require trix/dist/trix
@import "~bootstrap/scss/bootstrap";
@import "~trix/dist/trix";
@import '~@yaireo/tagify/dist/tagify';
@import 'highlight.js/styles/github.css';


$font-primary: 'Poppins', Arial, sans-serif;
$primary: #8895a0;

.hierarchy-select.dropdown .hs-searchbox {
  padding: 0 5px 4px
}

.hierarchy-select.dropdown .dropdown-menu a[data-level='2'] {
  padding-left: 40px
}

.hierarchy-select.dropdown .dropdown-menu a[data-level='3'] {
  padding-left: 60px
}

.hierarchy-select.dropdown .dropdown-menu a[data-level='4'] {
  padding-left: 80px
}

.hierarchy-select.dropdown .dropdown-menu a[data-level='5'] {
  padding-left: 100px
}

.hierarchy-select.dropdown .dropdown-menu a[data-level='6'] {
  padding-left: 120px
}

.hierarchy-select.dropdown .dropdown-menu a[data-level='7'] {
  padding-left: 140px
}

.hierarchy-select.dropdown .dropdown-menu a[data-level='8'] {
  padding-left: 160px
}

.hierarchy-select.dropdown .dropdown-menu a[data-level='9'] {
  padding-left: 180px
}

.hierarchy-select.dropdown .dropdown-menu a[data-level='10'] {
  padding-left: 200px
}

.avatar {
  width: 50px;
  height: 50px;

  img {
    border-radius: 150px;
    border: 1px solid white;
  }
}

a:not([class*="btn"]) {
  text-decoration: none;
}

a:not([class*="btn"]):hover {
  text-decoration: underline;
}

trix-editor {
  height: unset !important;
}

trix-toolbar .trix-button-group--file-tools {
  display: none;
}

// Action Text
.trix-content {
  .attachment-gallery {
    > action-text-attachment,
    > .attachment {
      flex: 1 0 33%;
      padding: 0 0.5em;
      max-width: 33%;
    }

    &.attachment-gallery--2,
    &.attachment-gallery--4 {
      > action-text-attachment,
      > .attachment {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }

  action-text-attachment {
    .attachment {
      padding: 0 !important;
      max-width: 100% !important;
    }
  }
}


.field_with_errors {
  color: #d43b4d;
}

// ----------------------------------------------------------------------------
// Notifications
// ----------------------------------------------------------------------------

#notification {
  position: absolute;
  top: 75px;
  left: 50%;
  width: 350px;
  margin-left: -175px;
  z-index: 1;
  padding: 2px 8px;

  .btn-close {
    height: auto !important;
    margin-top: -5px;
  }
}

body {
  font-family: $font-primary;
  font-size: 14px;
  line-height: 1.8;
  font-weight: normal;
  background: #fafafa;
  color: lighten($black, 50%);
}

a {
  transition: .3s all ease;
  color: $primary;

  &:hover, &:focus {
    text-decoration: none !important;
    outline: none !important;
    box-shadow: none;
  }
}

button {
  transition: .3s all ease;

  &:hover, &:focus {
    text-decoration: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
}

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
  line-height: 1.5;
  font-weight: 400;
  font-family: $font-primary;
  color: $black;
}


.ftco-section {
  padding: 7em 0;
}

.ftco-no-pt {
  padding-top: 0;
}

.ftco-no-pb {
  padding-bottom: 0;
}

//HEADING SECTION
.heading-section {
  font-size: 28px;
  color: $black;

  small {
    font-size: 18px;
  }
}

//COVER BG
.img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}


.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

.collapsing {
  -webkit-transition: none;
  transition: none;
  display: none;
}

#sidebar {
  background-color: rgb(136, 149, 160);

  h1 {
    text-align: center;
  }

  .btn-toggle {
    display: inline-flex;
    padding: 0.25rem 0.5rem;
    font-weight: 400;
    background-color: transparent;
    border: 0;
    color: white;
    font-size: 1rem;
    line-height: 0;
  }

  .btn-toggle::before {
    width: 1.25em;
    line-height: 0;
    #content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform .35s ease;
    transform-origin: 0.5em 50%;
    color: white;
  }
  .btn-toggle[aria-expanded="true"]::before {
    transform: rotate(90deg);
  }

  .btn-toggle-nav a {
    display: inline-flex;
    padding: 0rem 0.5rem;
    margin-left: 1.75rem;
    text-decoration: none;
    color: white !important;
  }

}


.logo {
  display: block;
  color: $white;
  font-weight: 900;
  padding: 10px 30px;
  @include transition(.3s);
}


.nav-link {
  transition: all .3s ease;
  color: #8895a0;
}

a[data-bs-toggle="collapse"] {
  position: relative;
}

#sidebarCollapse {
  span {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.footer {
  padding: 0 30px;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  transition: all 0.3s;
}


.btn {
  &.btn-primary {
    background: $primary;
    border-color: $primary;

    &:hover, &:focus {
      background: $primary !important;
      border-color: $primary !important;
    }
  }

  &.btn-outline-primary {
    border-color: $primary;

    &:hover, &:focus {
      background: none !important;
      border-color: $primary !important;
    }
  }
}

.chosen-block {
  .chosen-container {
    display: block
  }
}

// Search form
// ----------------------------
.form-search {
  background: #e0ebeb;
  border: solid 1px #c0d5d5;
  padding: 8px;
  margin-bottom: 16px;


  select {
    border: solid 1px #9cb6b6;
    padding: 2px 4px;
    height: 30px !important;
  }

  .actions {
    margin-top: 5px;

    input {
      width: auto;
      height: auto !important;
      padding: 4px 8px;
    }
  }

  .text-body-secondary {
    margin-left: 5px
  }

}


.help {

  .navbar {
    height: 60px

  }

  .help-page {
    margin-top: 65px;
  }


  .logo {
    width: 300px;
  }


  .sidebar {
    height: 100vh;
    overflow-y: auto;
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
    padding-top: 15px;
    background: aliceblue;
  }

  .main {
    margin-top: 60px;
    height: auto;
    min-height: 1000px;
    display: flex;
    flex-direction: column;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
  }

  .gutter {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7Z' fill='%23CDCDCD'/%3E%3Cpath d='M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z' fill='%23CDCDCD'/%3E%3Cpath d='M12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21Z' fill='%23CDCDCD'/%3E%3C/svg%3E%0A") no-repeat center center;
    cursor: col-resize;
    position: sticky;
    height: 100vh;
    top: 0%
  }

  .side-menu-right {
    top: 75px;
    width: 15%;
    margin: 60px 15px 15px;
    overflow-y: auto;
    overflow-x: hidden;
    position: sticky;
    height: 100vh;
  }

  .menu-parent {
    cursor: pointer;
  }

  .breadcrumb {
    font-size: 15px;

    .separator {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  #navbar.navbar {
    box-shadow: none;
  }

  #navbar {
    background: aliceblue !important;

    .nav-link {
      padding: 2px;
    }

    .row-menu a {
      margin-left: 5px;
    }

    .row-menu a:hover {
      text-decoration: underline !important;
    }

    .nav-link[aria-expanded="true"] .link-menu::before {
      transform: rotate(90deg);
    }

    .link-menu::before {
      width: 1em;
      line-height: 0;
      content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
      transition: transform 0.35s ease;
      transform-origin: 0.5em 50%;
      display: inline-block;
    }

    .window-title {
      a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
      background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
      color: #8895a0;
    }

    .collapsing {
      -webkit-transition: none;
      transition: none;
      display: none;
    }

  }

  .navbar .nav-pills .nav-link.active span, .navbar .nav-pills .show > .nav-link span {
    color: #FFFFFF !important;
  }

  .nav .new-page {
    padding-left: 10px
  }

  .attachment {
    margin: 20px 0 20px 0;
  }

  .title {
    color: #0081C1;
  }

  .trix-content {
    margin: 15px 0px
  }

  .image {
    max-width: 100%;
    height: auto;
  }

}


#planification {
  hr {
    margin: 5px
  }

  h4 {
    color: gray;
  }
}

.qr {
  border-width: 0;
  border-style: none;
  border-color: #0000ff;
  border-collapse: collapse;
}

.qr td {
  border-width: 0;
  border-style: none;
  border-color: #0000ff;
  border-collapse: collapse;
  padding: 0;
  margin: 0;
  width: 10px;
  height: 10px;
}

.qr td.black {
  background-color: #000;
}

.qr td.white {
  background-color: #fff;
}

.advanced-code-block table {
  width: auto;
  @extend .table;
  @extend .table-sm;
  @extend .table-bordered;
  @extend .table-striped;
}


/* Suggestions items */
.tagify__dropdown.users-list .tagify__dropdown__item {
  padding: .5em .7em;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0 1em;
  grid-template-areas: "avatar name"
                         "avatar email";
}

.tagify__dropdown.users-list .tagify__dropdown__item:hover .tagify__dropdown__item__avatar-wrap {
  transform: scale(1.2);
}

.tagify__dropdown.users-list .tagify__dropdown__item__avatar-wrap {
  grid-area: avatar;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  background: #EEE;
  transition: .1s ease-out;
}

.tagify__dropdown.users-list img {
  width: 100%;
  vertical-align: top;
}

.tagify__dropdown.users-list strong {
  grid-area: name;
  width: 100%;
  align-self: center;
}

.tagify__dropdown.users-list span {
  grid-area: email;
  width: 100%;
  font-size: .9em;
  opacity: .6;
}

.tagify__dropdown.users-list .addAll {
  border-bottom: 1px solid #DDD;
  gap: 0;
}


/* Tags items */
#users-list .tagify__tag {
  white-space: nowrap;
}

#users-list .tagify__tag:hover .tagify__tag__avatar-wrap {
  transform: scale(1.6) translateX(-10%);
}

#users-list .tagify__tag .tagify__tag__avatar-wrap {
  width: 16px;
  height: 16px;
  white-space: normal;
  border-radius: 50%;
  background: silver;
  margin-right: 5px;
  transition: .12s ease-out;
}

#users-list .tagify__tag img {
  width: 100%;
  vertical-align: top;
  pointer-events: none;
}

.board {
  display: flex;
}

.board-col {
  overflow-y: scroll;
  list-style: none;
  width: 20%;
  margin-left: 8px;
  padding: 0 8px 0 0;
  height: 75vh;
}

.board-col-name {
  background-color: lightblue;
  text-align: center;
  margin-bottom: 16px;
  padding: 16px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 99;
  height: 60px;
}

.board-col-item {
  background-color: white;
  margin-bottom: 4px;
  padding: 16px;
  cursor: grab;
}

.board-col-item:active {
  cursor: grabbing;
}

// Trix attachment formatting
.attachment--preview {
  margin: 0.6em 0;
  text-align: center;
  width: 100%;
}

.attachment {
  display: inline-block;
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

// Tribute styles
.trix-content {
  .MsoNormal {
    display: inline-block
  }
}

.tribute-container {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1), 0 5px 20px rgba(0, 0, 0, 0.05);
  z-index: 9999;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    background: #fff;
    padding: 0.2em 1em;
    min-width: 15em;
    max-width: 100%;
  }

  .highlight {
    background: #1b6ac9;
    color: #fff;

    span {
      font-weight: bold;
    }
  }
}

